/*-----------------------------------------------
|   Data table
-----------------------------------------------*/
table.dataTable {
  margin: 0 !important;
  border-color: var(--#{$prefix}border-color) !important;
  thead tr {
    border-top: 1px solid var(--#{$prefix}border-color);
  }
}


.dt-container {
  margin: 0 auto;

  .table-responsive {
    margin-bottom: map_get($spacers, 3);
    margin-top: map-get($spacers, 2);
  }
  .dt-search {
    display: flex;
    justify-content: center;
    @include media-breakpoint-up(md) {
      justify-content: end;
    }
    label {
      display: inline-flex;
      margin-bottom: 0;
      align-items: center;
      input {
        max-width: 15rem;
        display: unset !important;
        width: unset !important;
      }
    }
  }
  .dt-info {
    padding-top: 0 !important;
    font-size: map_get($font-sizes, 10);
  }
  .data-table.dataTable {
    border-collapse: collapse !important;
    td,
    th {
      white-space: nowrap;
    }
  }

  .paging_simple {
    .page-link {
      border-radius: $border-radius !important;
      margin-left: map-get($spacers, 2) !important;
      background-color: $primary;
      padding-left: map-get($spacers, 4) !important;
      padding-right: map-get($spacers, 4) !important;
      border: 0;
      color: $white;
      &:hover {
        background-color: darken($primary, 7.5%);
      }
    }
    .pagination {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  .dt-info {
    padding-top: 0 !important;
    color: var(--#{$prefix}gray-700) !important;
    text-align: left;
  }

  .disabled {
    .page-link {
      background-color: var(--#{$prefix}btn-falcon-background) !important;
      color: $gray-600;
    }
  }

  .dtr-details {
    margin-left: 1.875rem !important;
  }

  .sort {
    &:before {
      display: none !important;
    }
    &:after {
      position: relative !important;
      display: inline !important;
      top: 1px !important;
      bottom: auto !important;
      left: 0.50rem !important;
      opacity: 1 !important;
    }
    &.sorting {
      &:after {
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi41MjU1NiAzLjVDNi45NDkzNyAzLjUgNy4xNjEyOCAzLjA2MjUgNi44NTUxOSAyLjc5MTY3TDMuODQxNCAwLjEyNUMzLjY1MzA0IC0wLjA0MTY2NjcgMy4zNDY5NiAtMC4wNDE2NjY3IDMuMTU4NTkgMC4xMjVMMC4xNDQ4MDkgMi43OTE2N0MtMC4xNjEyNzkgMy4wNjI1IDAuMDUwNjI3OSAzLjUgMC40NzQ0NDEgMy41SDYuNTI1NTZaIiBmaWxsPSIjQjZDMkQyIi8+CjxwYXRoIGQ9Ik0wLjQ3NDQ0MiA1LjVDMC4wNTA2MjgyIDUuNSAtMC4xNjEyNzkgNS45Mzc1IDAuMTQ0ODA5IDYuMjA4MzNMMy4xNTg1OSA4Ljg3NUMzLjM0Njk2IDkuMDQxNjcgMy42NTMwNCA5LjA0MTY3IDMuODQxNDEgOC44NzVMNi44NTUxOSA2LjIwODMzQzcuMTYxMjggNS45Mzc1IDYuOTQ5MzcgNS41IDYuNTI1NTYgNS41TDAuNDc0NDQyIDUuNVoiIGZpbGw9IiNCNkMyRDIiLz4KPC9zdmc+Cg==");
      }
    }
    &.sorting_asc {
      &:after {
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNyA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi41MjU1NiAzLjVDNi45NDkzNyAzLjUgNy4xNjEyOCAzLjA2MjUgNi44NTUxOSAyLjc5MTY3TDMuODQxNCAwLjEyNUMzLjY1MzA0IC0wLjA0MTY2NjcgMy4zNDY5NiAtMC4wNDE2NjY3IDMuMTU4NTkgMC4xMjVMMC4xNDQ4MDkgMi43OTE2N0MtMC4xNjEyNzkgMy4wNjI1IDAuMDUwNjI3OSAzLjUgMC40NzQ0NDEgMy41SDYuNTI1NTZaIiBmaWxsPSIjQjZDMkQyIi8+Cjwvc3ZnPgo=") !important;
        top: -2px !important;
      }
    }
    &.sorting_desc {
      &:after {
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNyA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC40NzQ0NDIgMC41MDAwMDFDMC4wNTA2MjgyIDAuNTAwMDAxIC0wLjE2MTI3OSAwLjkzNzUgMC4xNDQ4MDkgMS4yMDgzM0wzLjE1ODU5IDMuODc1QzMuMzQ2OTYgNC4wNDE2NyAzLjY1MzA0IDQuMDQxNjcgMy44NDE0MSAzLjg3NUw2Ljg1NTE5IDEuMjA4MzNDNy4xNjEyOCAwLjkzNzUwMSA2Ljk0OTM3IDAuNTAwMDAxIDYuNTI1NTYgMC41MDAwMDFMMC40NzQ0NDIgMC41MDAwMDFaIiBmaWxsPSIjQjZDMkQyIi8+Cjwvc3ZnPgo=") !important;
        top: -2px !important;
      }
    }
  }

  .no-sort {
    padding-right: map-get($spacers, 1) !important;
    .dt-column-order{
      display: none !important;
      &:before,
      &:after {
        display: none !important;
      }
    }
  }

  .dt-scroll {
    .dt-scroll-head {
      width: auto !important;
        
      .dt-scroll-headInner {
        width: 100% !important;
      }
    }
    .dt-scroll-body {
      @extend .scrollbar;
      border-bottom: 1px solid var(--#{$prefix}border-color);
      width: 100% !important;
      table {
        width: 100% !important;
        thead tr {
          border: none !important;
        }
        .sort {
          &:before {
            display: none !important;
          }
          &:after {
            display: none !important;
          }
        }
      }
      
      thead tr {
        visibility: collapse !important;
        line-height: 0;
      }
    }
  }
}

.falcon-data-table {
  .data-table {
    margin: 0 !important;
  }
  .paging_full_numbers,
  .paging_first_last_numbers,
  .paging_full,
  .paging_numbers,
  .paging_simple_numbers {
    .page-link {
      @extend .btn;
      @extend .btn-falcon-default;
      margin-left: map-get($spacers, 1);
      margin-right: map-get($spacers, 1);
      padding: 3px 12px;
      border-radius: $border-radius !important;
      border: 1px solid var(--#{$prefix}btn-border-color) !important;
    }
    .page-item.active {
      .page-link {
        background-color: transparent;
        color: $primary !important;
      }
    }
  }
}

table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child:before {
  top: 0.3125rem;
}
.data-table-row-bulk-select {
  width: 1.75rem !important;
}
.data-table-row-action {
  width: 2.5rem !important;
}
table.dataTable.table-sm > thead > tr > th :not(.sorting_disabled) {
  padding-right: 0;
}

table.dataTable thead tr>.dtfc-fixed-left,table.dataTable thead tr>.dtfc-fixed-right{z-index:1;background-color:var(--#{$prefix}gray-200)}
table.dataTable tbody tr>.dtfc-fixed-left,table.dataTable tbody tr>.dtfc-fixed-right{z-index:1;background-color: var(--#{$prefix}gray-200)}
div.dtfc-left-top-blocker,div.dtfc-right-top-blocker{background-color:var(--#{$prefix}gray-200)}
div.dtfc-top-blocker{
  display: none !important;
}